import React from 'react'
import Layout from '../components/Layout'
import {graphql} from 'gatsby'

export const data = graphql`
  {
    site {
      info:siteMetadata {
        data
        description
        person {
          apellido
          nombre
        }
      }
    }
  }
`
const pagequeries = ({data}) => {
    const {
        site:{
            info:{
                person:{
                    nombre,
                    apellido
                }
            }
        }
    } = data
    return (
        <Layout>
            <h1 className="text-5xl text-center py-8">Sitio de Ejemplo</h1>
            <h1 className="text-center">Nombre: {nombre}</h1>
            <h1 className="text-center">Nombre: {apellido}</h1>

        </Layout>
    )
}

export default pagequeries